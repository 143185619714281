import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import EntryModal from './components/EntryModal';
import HomePage from './components/HomePage';
import { Provider } from 'react-redux';
import store from './features/store';
import '@coreui/icons/css/free.css';

const App = () => {

    return (
        <Provider store={store}>
            <div className="MaroonBaseApp">
                <Route path="/maroonbase/resource-guide" component={HomePage} />
                <Route path="/maroonbase/resource-guide/mb/:id" component={EntryModal} />
            </div>
        </Provider>
    );

}

export default withRouter(App);
