import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { UserOptions, CategoryOptions, FocusOptions, SubcategoryOptions, showSubcategoryOptions, ThemeOptions, showThemeOptions } from '../data/DataMapping';
import '../scss/sidebar.scss';

class SearchForm extends Component {

    handleFilterByUser = (event) => {
        this.props.filterByUser(event.target.value)
    }

    handleFilterByCategory = (event) => {
        this.props.filterByCategory(event.target.value);
    }

    handleFilterByFocus = (event) => {
        this.props.filterByFocus(event.target.value);
    }

    handleFilterBySubCategory = (event) => {
        this.props.filterBySubCategory(event.target.value);
    }

    handleFilterByTheme = (event) => {
        this.props.filterByTheme(event.target.value);
    }

    handleReset = (event) => {
        event.persist();
        event.preventDefault();
        this.props.resetFilters();
    }

    handleSearch = (event) => {
        event.persist();
        event.preventDefault();
        this.props.handleSearch(event.target.value);
    }

    handleSubmit = (event) => {
        event.stopPropagation();
        event.preventDefault();
    }

    render() {

        const { searchValues } = this.props;

        return (
            <Form className="mb-sidebar-wrapper" onSubmit={this.handleSubmit} >
                <FormGroup className="mb-search-box">
                    <Input id="searchBox" type="search" placeholder="Search..." name="searchBox" onChange={this.handleSearch} value={searchValues.searchTerm} />
                    <div className="form-icon" onClick={this.handleSearch}><i className="cil-magnifying-glass"></i></div>
                </FormGroup>

                <div tag="fieldset" className="text-left mt-5">
                    <h4>What are you looking for?</h4>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="filterByCategory" value='' onChange={this.handleFilterByCategory} checked={searchValues.category === ''} /> All</Label>

                        {CategoryOptions.map((category, index) => {
                            return (
                                <Label key={index} check>
                                    <Input type="radio" name="filterByCategory" value={category} onChange={this.handleFilterByCategory} checked={searchValues.category === category} />{' '}{category}
                                </Label>
                            );
                        })}
                    </FormGroup>
                </div>


                {showSubcategoryOptions(searchValues.category) &&
                    <div tag="fieldset" className="text-left mt-5">
                        <h4>Filter By Type</h4>

                        {SubcategoryOptions.map((subcat, key) => {

                            return (
                                <FormGroup check key={key}>
                                    <Label check>
                                        <Input type="checkbox" value={subcat} onChange={this.handleFilterBySubCategory} checked={!!searchValues.subcat.includes(subcat)} /> {subcat}
                                    </Label>
                                </FormGroup>
                            );
                        })}

                    </div>}

                {showThemeOptions(searchValues.category) &&
                    <div tag="fieldset" className="text-left mt-5">
                        <h4>Filter By Theme</h4>

                        {ThemeOptions.map((theme, key) => {

                            return (
                                <FormGroup check key={key}>
                                    <Label check>
                                        <Input type="checkbox" value={theme} onChange={this.handleFilterByTheme} checked={!!searchValues.theme.includes(theme)} /> {theme}
                                    </Label>
                                </FormGroup>
                            );
                        })}

                    </div>}


                <div tag="fieldset" className="text-left mt-5">
                    <h4>Filter by User</h4>
                    <FormGroup check >
                        <Label check>
                            <Input type="radio" name="filterByUser" value='' onChange={this.handleFilterByUser} checked={searchValues.user === ''} /> All</Label>

                        {UserOptions.map((user, index) => {

                            return (

                                <Label key={index} check>
                                    <Input type="radio" name="filterByUser" value={user} onChange={this.handleFilterByUser} checked={searchValues.user === user} />{' '}{user}
                                </Label>

                            );
                        })}
                    </FormGroup>
                </div>


                <FormGroup className="text-left mt-5">
                    <h4>Filter by Focus</h4>

                    {FocusOptions.map((focus, key) => {

                        return (
                            <FormGroup check key={key}>
                                <Label check>
                                    <Input type="checkbox" value={focus} onChange={this.handleFilterByFocus} checked={!!searchValues.focus.includes(focus)} />{' '}{focus}
                                </Label>
                            </FormGroup>
                        );
                    })}
                </FormGroup>

                <FormGroup className="text-center">
                    <Button
                        onClick={this.handleReset}
                        className="innov-btn theme-button hvr hvr-float mt-5"
                        type="reset"
                    >
                        Reset Filters <i className="cil-reload"></i>
                    </Button>
                </FormGroup>


            </Form>
        );
    }
}

export default SearchForm;