import { createSlice } from '@reduxjs/toolkit'
import entriesApi from '../api/entries.api'

export const entriesSlice = createSlice({
    name: 'entriesStore',
    initialState: {
        isLoading: false,
        entries: [],
        totalEntries: 0,
    },
    reducers: {
        loadEntriesStart: state => {
            state.isLoading = true;
        },
        loadEntriesSuccess: (state, action) => {
            state.entries = action.payload;
            state.isLoading = false;
            state.totalEntries = action.payload.length;
        },
        loadEntriesFailure: state => {
            state.isLoading = false;
        },
    }
})

// the state is the Root state, so you need to reference the usersStore here
export const selectEntries = state => state.entriesStore.entries;
export const selectPage = state => state.entriesStore.page;
export const selectTotal = state => state.entriesStore.totalCount;
export const selectIsLoading = state => state.entriesStore.isLoading;
export const selectTotalEntries = state => state.entriesStore.totalEntries;

// Don't expose to rest of the app.
const {
    loadEntriesStart,
    loadEntriesSuccess,
    loadEntriesFailure
} = entriesSlice.actions


export const loadEntries = () => async dispatch => {
    dispatch(loadEntriesStart());
    try {
        let response = await entriesApi.getEntries();
        dispatch(loadEntriesSuccess(response));
    } catch (err) {
        console.error(err);
        dispatch(loadEntriesFailure());
    }
}

export default entriesSlice.reducer
