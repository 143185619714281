import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { displayDateTime, regex } from '../data/DataMapping';
import '../scss/single_result.scss';

class SingleResult extends Component {

    render() {

        const { result } = this.props;

        let imageSource = null;
        if (!result.image) {
            imageSource = 'https://innovation.tamu.edu/wp-content/plugins/wp-maroonbase/app/build/images/default.png';
        } else {
            let imageUrlFormatted = result.image.replace(/.*~/g, "");
            imageSource = imageUrlFormatted;
        }

        let resultUrl = "/maroonbase/resource-guide/mb/" + result.id;
        let title = result.title.replace(regex, '');
        let resultDisplayDateTime = displayDateTime(result.starttime, result.endtime);

        return (

            <div className="single-result-wrapper">
                <Link to={resultUrl}>
                    <div className="single-result-inner">
                        <div className="single-result d-flex flex-wrap justify-content-center">
                            <div className="single-result-image w-100" style={{ backgroundImage: `url(${imageSource})` }}>
                                {result.mbpoints > 0 ? <div className="single-result-points-container">
                                    <div className="single-result-points">{result.mbpoints} points</div>
                                </div> : false}
                            </div>
                            <div className="single-result-details text-start d-flex flex-wrap align-content-start">
                                <h4 className="w-100">{title}</h4>
                                {resultDisplayDateTime ?
                                    <h5 className="mt-3 w-100">
                                        {resultDisplayDateTime}
                                    </h5> : ''}
                            </div>
                        </div>
                    </div>
                </Link>
            </div >

        );
    }
}

export default SingleResult;