import React, { useEffect, useState, useCallback } from 'react';
import Sidebar from './Sidebar';
import { withRouter } from 'react-router-dom';
import _ from "lodash";
import { showSubcategoryOptions } from '../data/DataMapping';
import SingleResult from './SingleResult';
import { showDateSort } from '../data/DataMapping';
import { loadEntries, selectEntries, selectIsLoading, selectTotalEntries } from '../features/entriesSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import { Label, Form, Input } from 'reactstrap';
import "../scss/main.scss";
import '../scss/display_results.scss';
import { current } from '@reduxjs/toolkit';


const HomePage = () => {

    const entries = useSelector(selectEntries);
    const isLoading = useSelector(selectIsLoading);
    const totalEntries = useSelector(selectTotalEntries);

    const [resultsPerPage, setResultsPerPage] = useState(48);
    const [activePage, setActivePage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [sortBy, setSortBy] = useState('titleAsc');
    const [sortedResults, setSortedResults] = useState([]);
    const [displayResults, setDisplayResults] = useState([]);
    const [pageRange, setPageRange] = useState([]);
    const [searchValues, setSearchValues] = useState({ user: '', category: '', focus: [], subcat: [], theme: [], searchTerm: '' });

    const delayedSearch = useCallback(_.debounce((searchValues, entries) => runResults(searchValues, entries), 1000), []);

    const dispatch = useDispatch();

    // load entries on initial load
    useEffect(() => {
        if (!entries.length) {
            dispatch(loadEntries());
            setTotalResults(totalEntries);
        } else {
            const initialSortedResults = sortResultsBy(entries, sortBy);
            setSortedResults(initialSortedResults);
            setDisplayResults(initialSortedResults.slice(0, resultsPerPage));
            setTotalResults(initialSortedResults.length);
            setResultsPerPage(48);
            let totalNumberOfPages = Math.ceil(initialSortedResults.length / resultsPerPage);
            let initialPageRange = [];
            for (let i = 1; i <= totalNumberOfPages; i++) {
                initialPageRange.push(i);
            }
            setPageRange(initialPageRange);
        }
    }, [entries, totalEntries, dispatch]);


    function sortResultsBy(array, sortBy) {

        let sortedArray = [...array];
        if (sortBy === 'dateDesc') {
            sortedArray.sort((a, b) => (b.starttime.trim() > a.starttime.trim()) ? 1 : -1);
        } else if (sortBy === 'dateAsc') {
            sortedArray.sort((a, b) => (a.starttime.trim() > b.starttime.trim()) ? 1 : -1);
        } else if (sortBy === 'titleDesc') {
            sortedArray.sort((a, b) => (b.title.toLowerCase().trim() > a.title.toLowerCase().trim()) ? 1 : -1);
        } else {
            sortedArray.sort((a, b) => (a.title.toLowerCase().trim() > b.title.toLowerCase().trim()) ? 1 : -1);
        }
        return sortedArray;
    }

    function handleChangeSort(event) {
        let sortByValue = event.target.value;
        let newSortedResults = sortResultsBy(sortedResults, sortByValue);
        setSortedResults(newSortedResults);
        setPageRange(generatePageRange(newSortedResults));
        setDisplayResults(newSortedResults.slice(0, resultsPerPage));
        setActivePage(1);
        setSortBy(sortByValue);
        setTotalResults(newSortedResults.length);
    }

    function generatePageRange(results) {
        let totalNumberOfPages = Math.ceil(results.length / resultsPerPage);
        let newPageRange = [];
        for (let i = 1; i <= totalNumberOfPages; i++) {
            newPageRange.push(i);
        }
        return newPageRange;
    }

    function handleChangePage() {
        let newPage = event.target.value;
        const currentResults = [...sortedResults];
        let start = (newPage - 1) * resultsPerPage;
        let end = start + resultsPerPage;
        setDisplayResults(currentResults.slice(start, end));
        setActivePage(newPage);
    }

    function filterByUser(user) {
        const updateSearchValues = { ...searchValues };
        updateSearchValues.user = user;
        setSearchValues(updateSearchValues);
        runResults(updateSearchValues, entries);
    }

    function filterByCategory(category) {
        const updateSearchValues = { ...searchValues };
        updateSearchValues.category = category;
        // if the selected category does not have subcategories, then clear out the current subcategories
        updateSearchValues.subcat = showSubcategoryOptions(category) ? updateSearchValues.subcat : [];
        setSearchValues(updateSearchValues);
        runResults(updateSearchValues, entries);
    }

    function filterByFocus(focus) {
        const updateSearchValues = { ...searchValues };
        let focusArray = updateSearchValues.focus;

        if (focusArray.includes(focus)) {
            let index = focusArray.indexOf(focus);
            focusArray.splice(index, 1);
        } else {
            focusArray.push(focus);
        }

        updateSearchValues.focus = focusArray;
        setSearchValues(updateSearchValues);
        runResults(updateSearchValues, entries);
    }

    function filterBySubCategory(subcat) {
        const updateSearchValues = { ...searchValues };
        let subCatArray = updateSearchValues.subcat;

        if (subCatArray.includes(subcat)) {
            let index = subCatArray.indexOf(subcat);
            subCatArray.splice(index, 1);
        } else {
            subCatArray.push(subcat);
        }

        updateSearchValues.subcat = subCatArray;
        setSearchValues(updateSearchValues);
        runResults(updateSearchValues, entries);
    }

    function filterByTheme(theme) {
        const updateSearchValues = { ...searchValues };
        let themeArray = updateSearchValues.theme;

        if (themeArray.includes(theme)) {
            let index = themeArray.indexOf(theme);
            themeArray.splice(index, 1);
        } else {
            themeArray.push(theme);
        }

        updateSearchValues.theme = themeArray;
        setSearchValues(updateSearchValues);
        runResults(updateSearchValues, entries);
    }

    function handleSearch(thisSearchTerm) {
        const updateSearchValues = { ...searchValues };
        updateSearchValues.searchTerm = thisSearchTerm;
        const newSearchValues = { ...updateSearchValues, searchTerm: thisSearchTerm };
        setSearchValues(newSearchValues);
        delayedSearch(newSearchValues, entries);
    }

    function runResults(searchValues, entries) {

        let results = entries;

        // user
        if (searchValues.user) {
            results = results.filter(result => result.user.split(',').indexOf(searchValues.user) >= 0);
        }

        // category
        if (searchValues.category) {
            results = results.filter(result => result.category === searchValues.category);
        }

        // focus areas
        if (searchValues.focus.length > 0) {
            let focusResults = [];
            let focusArray = searchValues.focus;
            if (focusArray.length === 1) {
                let thisFocus = focusArray[0];
                if (thisFocus !== 'all') results = results.filter(result => result.focus.split(',').indexOf(thisFocus) >= 0);
            } else if (focusArray.length > 1) {
                for (let i = 0; i < focusArray.length; i++) {
                    let thisFocus = focusArray[i];
                    let theseFocusResults = results.filter(result => result.focus.split(',').indexOf(thisFocus) >= 0);
                    focusResults = focusResults.concat(theseFocusResults);
                }
                results = focusResults;
            }
        }

        // subcategories
        if (searchValues.subcat.length > 0) {
            let subCatResults = [];
            let subCatArray = searchValues.subcat;
            if (subCatArray.length === 1) {
                let thisSubCat = subCatArray[0];
                if (thisSubCat !== 'all') results = results.filter(result => result.subcat.split(',').indexOf(thisSubCat) >= 0);
            } else if (subCatArray.length > 1) {
                for (let i = 0; i < subCatArray.length; i++) {
                    let thisSubCat = subCatArray[i];
                    let theseSubCatResults = results.filter(result => result.subcat.split(',').indexOf(thisSubCat) >= 0);
                    subCatResults = subCatResults.concat(theseSubCatResults);
                }
                results = subCatResults;
            }
        }

        // theme
        if (searchValues.theme.length > 0) {
            let themeResults = [];
            let themeArray = searchValues.theme;
            if (themeArray.length === 1) {
                let thisTheme = themeArray[0];
                if (thisTheme !== 'all') results = results.filter(result => result.theme.split(',').indexOf(thisTheme) >= 0);
            } else if (themeArray.length > 1) {
                for (let i = 0; i < themeArray.length; i++) {
                    let thisTheme = themeArray[i];
                    let theseThemeResults = results.filter(result => result.theme.split(',').indexOf(thisTheme) >= 0);
                    themeResults = themeResults.concat(theseThemeResults);
                }
                results = themeResults;
            }
        }

        // search by searchTerm
        if (searchValues.searchTerm) {
            let searchResults = [];
            let searchTerm = searchValues.searchTerm.trim();

            if (searchTerm) {
                let resultsTitle = results.filter(result => result.title.toLowerCase().includes(searchTerm));
                let resultsDescription = results.filter(result => result.description.toLowerCase().includes(searchTerm));
                let resultsAddInfo = results.filter(result => result.addinfo.toLowerCase().includes(searchTerm));
                let resultsContactName = results.filter(result => {
                    return (result.cofname.toLowerCase() + ' ' + result.colname.toLowerCase()).includes(searchTerm)
                });

                results = searchResults.concat(resultsTitle, resultsDescription, resultsAddInfo, resultsContactName);
            }

        }

        // make sure our result only has distinct values
        const finalResult = [];
        const map = new Map();
        for (const item of results) {
            if (!map.has(item.id)) {
                map.set(item.id, true);
                finalResult.push(item);
            }
        }
        let newSortedResults = sortResultsBy(finalResult, sortBy);
        setSortedResults(newSortedResults);
        setPageRange(generatePageRange(newSortedResults));
        setDisplayResults(newSortedResults.slice(0, resultsPerPage));
        setActivePage(1);
        setTotalResults(newSortedResults.length);
    }

    function resetFilters() {
        let resetSearchValues = { user: '', category: '', focus: [], subcat: [], theme: [], searchTerm: '' };
        setSearchValues(resetSearchValues);
        runResults(resetSearchValues, entries);
    }

    let dataMessage = 'No results found.';
    if (isLoading) dataMessage = 'Loading the MaroonBase Resource Guide...';

    return (
        <div className="MaroonBaseApp d-flex flex-wrap">

            <div className="mb-sidebar">
                <Sidebar
                    filterByUser={(user) => filterByUser(user)}
                    filterByCategory={(category) => filterByCategory(category)}
                    filterByFocus={(focus) => filterByFocus(focus)}
                    filterBySubCategory={(subcat) => filterBySubCategory(subcat)}
                    filterByTheme={(theme) => filterByTheme(theme)}
                    resetFilters={resetFilters}
                    handleSearch={(searchTerm) => handleSearch(searchTerm)}
                    searchValues={searchValues}
                />
            </div>

            <div className="mb-content">

                <div className="mb-3 d-flex flex-nowrap justify-content-between align-items-center">
                    <div>
                        <p className="results-found">Here's What We Found</p>
                        {totalResults > 0 && <p className="mt-3 mb-0">Showing Results {(resultsPerPage * (activePage - 1)) + 1} - {resultsPerPage * activePage < totalResults ? resultsPerPage * activePage : totalResults} of {totalResults}</p>}
                    </div>

                    <Form>
                        <Input type="select" id="sortby-select" name="sortby-select" value={sortBy} onChange={(event) => handleChangeSort(event)} className="sortby-select rounded-shadow p-2">
                            <option value="titleAsc">Sort by Title A-Z</option>
                            <option value="titleDesc">Sort by Title Z-A</option>

                            {showDateSort(searchValues.category) &&
                                <><option value="dateAsc">Sort by Date ASC</option>
                                    <option value="dateDesc">Sort by Date DESC</option></>}
                        </Input>
                    </Form>
                </div>

                <div className="d-flex justify-content-evenly align-items-start flex-wrap">

                    {displayResults.map((result) => {
                        return (
                            <SingleResult
                                result={result}
                                key={result.id}
                            />
                        )
                    })}
                </div>

                {totalResults > 0 ?
                    <div className="mb-pagination my-5">
                        {totalResults > resultsPerPage &&
                            <ul>
                                {pageRange.map((page, key) => {
                                    return <li key={key} value={page} onClick={() => handleChangePage()} className={activePage === page ? 'active' : ''}>{page}</li>
                                })}
                            </ul>}
                    </div> : <p>{dataMessage}</p>}

            </div>

            {isLoading && <Loader />}

        </div>
    );

}

export default withRouter(HomePage);
