import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Col } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectEntries, loadEntries } from '../features/entriesSlice';
import { displayDateTime, regex } from '../data/DataMapping';
import Loader from './Loader';
import '../scss/entry_modal.scss';

const EntryModal = (props) => {

    const entries = useSelector(selectEntries);
    const [entry, setEntry] = useState([]);
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    // load entries on initial load
    useEffect(() => {
        if (!entries.length) {
            dispatch(loadEntries());
        } else {
            let id = props.match.params.id;
            let currentEntry = entries.filter(result => result.id === id);
            setEntry(currentEntry[0]);
        }

    }, [entries, props.match.params.id, dispatch]);

    let fieldOfFocusArray = [];
    let userArray = [];
    let subCatArray = [];
    let title, description, addinfo, department;
    let imageSource = null;
    let contactName;
    let entryDisplayDateTime = null;

    if (entry) {

        // format the date/time
        entryDisplayDateTime = displayDateTime(entry.starttime, entry.endtime);

        if (entry.focus) {
            fieldOfFocusArray = entry.focus.split(",").filter(i => i !== '');
        }

        if (entry.user) {
            userArray = entry.user.split(',').filter(i => i !== '');
        }

        if (entry.subcat) {
            subCatArray = entry.subcat.split(',').filter(i => i !== '');
        }

        contactName = (entry.cofname || entry.colname) ? formatContactName(entry.coprefix, entry.cofname, entry.colname) : '';

        if (!entry.image) {
            imageSource = 'https://innovation.tamu.edu/wp-content/plugins/wp-maroonbase/app/build/images/default.png';
        } else {
            let imageUrlFormatted = entry.image.replace(/.*~/g, "");
            imageSource = imageUrlFormatted;
        }

        if (entry.title) title = entry.title.replace(regex, '');
        if (entry.description) description = entry.description.replace(regex, '');
        if (entry.addinfo) addinfo = entry.addinfo.replace(regex, '');
        if (entry.department) department = entry.department.replace(regex, '');
    }

    function formatContactName(prefixName, firstName, lastName) {
        prefixName = prefixName || '';
        firstName = firstName || '';
        lastName = lastName || '';
        let contactName = prefixName + ' ' + firstName + ' ' + lastName;
        return contactName;
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} fade={true} size="xl" className="mb-modal" backdrop={false}>

            <ModalHeader className="d-flex flex-nowrap justify-content-end">
                <Link to="/maroonbase/resource-guide/" className="d-flex flex-wrap align-items-center align-content-center"><span className="mb-0">Close </span><i className="cil-x font-weight-bold"></i></Link>
            </ModalHeader>

            <ModalBody>
                <div className="p-3">

                    <Row className="first-row">
                        <Col className="w-50">
                            <div className="d-flex flex-wrap h-100 align-content-between">

                                {title && <div className="w-100"><h1 className="w-100 mt-3">{title}</h1></div>}

                                {entry.owner &&
                                    <div className="mt-3 w-100">
                                        <h3>Owner</h3>
                                        <div className="w-100">{entry.owner}
                                        </div>
                                    </div>}

                                {entry.department &&
                                    <div className="mt-3 w-100">
                                        <h3>Department</h3>
                                        <div className="w-100">{department}
                                        </div>
                                    </div>}

                                {entry.subcat &&
                                    <div className="mt-3 w-100">
                                        <h3>Type</h3>
                                        <div>
                                            {subCatArray.map((value, key) => {
                                                return <span key={key}>{value}{key < subCatArray.length - 1 ? ", " : ""} </span>
                                            })}
                                        </div></div>}
                            </div>

                        </Col>

                        <Col className="resource-image w-50">
                            <div className="d-flex">
                                <img src={imageSource} alt={title} className="w-100 rounded-shadow" />
                            </div>
                            {entry.mbpoints > 0 ? <div className="resource-points mt-5 text-end">{entry.mbpoints} points</div> : false}
                        </Col>

                    </Row>

                    <Row className="mt-3">
                        <Col className="w-100 d-flex flex-wrap">
                            {entry.description &&
                                <div className="mt-3 w-100">
                                    <h3>Description</h3>
                                    <div dangerouslySetInnerHTML={{ __html: description }} />
                                </div>}

                            {entry.addinfo &&
                                <div className="mt-3 w-100">
                                    <h3>Additional Information</h3>
                                    <div dangerouslySetInnerHTML={{ __html: addinfo }}></div>
                                </div>}

                        </Col>
                    </Row>


                    <Row className="pb-5">
                        <Col className="w-50 d-flex flex-wrap">
                            {entryDisplayDateTime &&
                                <div className="mt-3 w-100">
                                    <h3>Date Info</h3>
                                    <div>{entryDisplayDateTime}</div>
                                </div>}

                            {entry.accessinfo &&
                                <div className="mt-3 w-100">
                                    <h3>Access Information</h3>
                                    <p>{entry.accessinfo}</p>
                                </div>}

                            {(entry.address1 || entry.address2 || entry.city || entry.locationmap || entry.building || entry.zip) &&
                                <div className="mt-3 w-100">
                                    <h3>Location Information</h3>
                                    {entry.building && <div>{entry.building}</div>}
                                    {entry.locationDescription && <div>{entry.locationDescription}</div>}
                                    {entry.address1 && <div>{entry.address1}</div>}
                                    {entry.address2 && <div>{entry.address2}</div>}
                                    {(entry.city || entry.state || entry.zip) && <div>
                                        <span>{entry.city ? entry.city + ', ' : ''}</span><span>{entry.state ? entry.state + ' ' : ''}</span><span>{entry.zip ? entry.zip : ''}</span>
                                    </div>}
                                    {entry.locationmap && <div><a href={entry.locationmap} target="_blank" alt="Location Map" rel="noopener noreferrer">{entry.locationmap}</a></div>}
                                </div>}


                            <div className="mt-4 w-100">
                                <h3>Contact Information</h3>
                                <div>Name: {contactName ? contactName : 'TBD'}</div>
                                <div>Email: {entry.coemail ? <a href={"mailto:" + entry.coemail} alt="Contact Email">{entry.coemail}</a> : 'TBD'}</div>
                                <div>Phone: {entry.cophone ? <a href={"tel:" + entry.cophone}>{entry.cophone}</a> : 'TBD'}</div>
                            </div>
                        </Col>

                        <Col className="w-50 d-flex flex-wrap">

                            {entry.user && <div className="mt-3 w-100">
                                <h3>Users</h3>
                                <div>{userArray.map((value, key) => {
                                    return <span key={key}>{value}{key < userArray.length - 1 ? ", " : ""} </span>
                                })}</div></div>}

                            {entry.focus || entry.theme ?
                                <div className="d-flex w-100 flex-wrap">
                                    {entry.focus &&
                                        <div className="mt-3 half-column">
                                            <h3>Field of Focus</h3>
                                            <p>{fieldOfFocusArray.map((value, key) => {
                                                return <span className="focus" key={key}>{value}{key < fieldOfFocusArray.length - 1 ? ", " : ""}</span>
                                            })}
                                            </p>
                                        </div>}

                                    {entry.theme &&
                                        <div className="mt-3 half-column">
                                            <h3>Theme</h3>
                                            <p>{fieldOfFocusArray.map((value, key) => {
                                                return <span className="focus" key={key}>{value}{key < fieldOfFocusArray.length - 1 ? ", " : ""}</span>
                                            })}
                                            </p>
                                        </div>}
                                </div>
                                : false}


                            {(entry.website || entry.rsvpLink || entry.meetingLink) ?
                                <div>
                                    <div className="w-100">
                                        {entry.website &&
                                            <Button className="innov-btn theme-button hvr hvr-float me-3 mt-3 mb-btn" href={entry.website} target="_blank" >Visit Website</Button>
                                        }
                                        {entry.rsvpLink &&
                                            <Button className="innov-btn theme-button hvr hvr-float me-3 mt-3 mb-btn" href={entry.website} target="_blank" >RSVP</Button>
                                        }
                                        {entry.meetingLink &&
                                            <Button className="innov-btn theme-button hvr hvr-float mt-3 mb-btn" href={entry.website} target="_blank" >Meeting Link</Button>
                                        }
                                    </div>
                                </div>
                                : false}
                        </Col>


                    </Row>

                </div>

            </ModalBody>
            <ModalFooter className="d-flex flex-nowrap justify-content-end">
                <Link to="/maroonbase/resource-guide/" className="d-flex flex-nowrap align-items-center align-content-center"><p className="mb-0">Close </p><i className="cil-x font-weight-bold"></i></Link>
            </ModalFooter>
            {!title && <Loader />}
        </Modal >
    );

}

export default withRouter(EntryModal);
