import apiUrl from '../config';

const getApiUrl = () => {
    return apiUrl;
}

const get = async (url, additionalHeaders) => {
    const newUrl = new URL(url);

    const headers = {
        ...additionalHeaders,
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };
    const response = await fetch(newUrl, {
        method: "GET",
        headers: headers
    });
    if (response.ok) {
        return await response.json();
    }
    throw new Error("network error");
}

const suppressApiErrors = () => {};

export default {
    getApiUrl,
    get,
    suppressApiErrors,
};
