var dateFormat = require("dateformat");

export const formatDate = (date) => {
    var d = new Date(date);
    return dateFormat(d, "mmm. d, yyyy");
}

export const formatTime = (time) => {
    var t = new Date(time);
    return dateFormat(t, "h:MM TT");
}

export const displayDateTime = (start, end) => {

    let displayDateTime = null;
    var startDateTime = new Date(start);
    var endDateTime = new Date(end);

    if (start) {

        // first convert string to date
        var startDate = formatDate(startDateTime);
        var startTime = formatTime(startDateTime);

        // then check to make sure the date is reasonable - if really far in the past or really far in the future, do not show it
        if (startDateTime.getFullYear() >= 2020 && startDateTime.getFullYear() < 2100) {
            displayDateTime = startDate + ' ' + startTime;

            // display the end date/time if exists
            if (end) {

                let endDate = formatDate(endDateTime);
                let endTime = formatTime(endDateTime);

                if (endDateTime.getFullYear() >= 2020 && endDateTime.getFullYear() < 2100) {

                    if (endDate === startDate) {
                        displayDateTime += ' - ' + endTime;
                    } else {
                        displayDateTime += ' - ' + endDate + ' ' + endTime;
                    }
                }
            }
        }

    }

    return displayDateTime;
}

export const regex = '/[^\x20-\x7E]+/g';

export const CategoryOptions = ['Centers and Programs', 'Courses', 'Events', 'Research', 'Resources and Tools', 'Student Organizations']

// subcat and type are the same thing
export const SubcategoryOptions = ['1 on 1 Session', 'Accelerator/Incubator', 'Career Exploration and Networking', 'Ceremony/Tradition', 'Class', 'Collaborative Space', 'Competition/Contest', 'Conference/Symposium', 'Exhibit', 'Fair/Festival', 'Film/Entertainment', 'Fundraiser', 'Hackathon/Ideation Session', 'Information Session', 'Laboratory', 'Lecture', 'Living Learning', 'Makerspace', 'Meal', 'Meeting', 'Mentorship Opportunity', 'Online Tool or Resource', 'Open Event', 'Organization/Office', 'Party', 'Performance', 'Projects to Join', 'Reception', 'Social', 'Speaker', 'Study Space', 'Technology/Tools', 'Training/Workshop', 'Volunteer Opportunity'];

export const showSubcategoryOptions = (category) => {
    return (category === 'Resources and Tools' || category === 'Events');
}

export const ThemeOptions = ['Arts & Music', 'Athletics', 'Cultural', 'Diversity', 'Fundraising', 'Group Business', 'Health/Exercise', 'Learning', 'Service'];

export const showThemeOptions = (category) => {
    return category === 'Events';
}

export const UserOptions = ['Faculty', 'Former Students and Friends', 'Grad/Prof Students', 'PostDocs', 'Staff', 'Undergraduate Students'];

export const FocusOptions = ['Innovation', 'Creativity', 'Leadership', 'Entrepreneurship', 'Other'];

export const showDateSort = (category) => {
    return category === 'Events';
}