import api from '.';

const getEntries = async () => {
    const fetchApiUrl = `${api.getApiUrl()}`;
    return api.get(fetchApiUrl, {});
};

export default {
    getEntries
};
