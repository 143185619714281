import React from 'react';
import '../scss/loader.scss';

function Loader() {
  return (
    <div className="loader">
      <i className="cil-reload"></i>
    </div>
  );
}

export default Loader;